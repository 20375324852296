<template>
  <div>
    <!--
      <b-card class="card-congratulation-medal">
      <h5>Welcome 🎉</h5>
      <b-card-text class="font-small-3"> Welcome to Automatum </b-card-text>
    </b-card>
    -->
    <b-card>
      <b-tabs pills>
        <b-tab title="AWS Marketplace" active>
          <b-card-text>
            <h3> What is this? </h3>
            <p>A service to help you list your SaaS, SaaS Contract, Container based, API or Data catalogue product on the AWS marketplace.</p>
            <h3> Who Can This HELP? </h3>
            <p>A service to help you list your SaaS, SaaS Contract, Container based, API or Data catalogue product on the AWS marketplace.</p>
            <ul>
            <li>Have a clear separation between production and non-production environments</li>
            <li>Avoid error prone manual deployments</li>
            <li>Improve data classification and protection</li>
            <li>Mandate compliant security controls</li>
            <li>Work around inconsistant naming conventions</li>
            <li>Improve their budgeting/billing strategies</li>
            <li>Poor identity and access management</li>
            </ul>
            <h3> The PROCESS </h3>
            <p>In 2-6 weeks we integrate your product by:</p>    
            <ul>
              <li>Discussing your pricing and deployment models  during a 1-2 hour team meeting</li>
              <li>We will work behind the scenes to list your product  directly on Marketplace. </li>
              <li>Within 1 to 6 weeks your product will be live</li>
            </ul>
            <h3> key features </h3>
            <p>The Complex integrations done for you:</p>
            <ul>
              <li>Minimal time commitment for your team</li>
              <li>Support and education on how to operate the listing and use the solution</li>
              <li>Ongoing support for the implemented solution free of charge</li>
            </ul>
            <h3> Use case</h3>
                      <!-- <div id="app" style="col:flex">
                        <img src='@/assets/images/usecases/drwho.png'>
                    </div> -->
            <p> Concord Teams craeted an appliaction called Dr Who which is a personality profile, team builder and conflict resolution app.​ </p>

            <p>They wanted to get their product listd on Marketplace enabling their bigger customers to purchase through AWS Marketplace. They used our platform to list their product on Marketplace in uder a week with little commitment from their team. They could sucessfully put their first deal through after listing and now have access to a global sales channel.</p>
            <p> We are now supporting Concord Team to transform this sales channel and get the most out of their investment.</p>

            <p>
            You can start by clicking on <b>AWS Marketplace</b> on the left hand side to start. 
            We have a list of most commonly asked questions to get your started. Please reach out if you have any other questions. 
            </p>
          </b-card-text>


        </b-tab>
        <b-tab title="AWS Landing Zone" active>
          <b-card-text>
              <h3> What is this? </h3>
            <p>A time-saving solution to setup a reliable environment for running secure and scalable workloads.</p>
            <h3> Who Can This HELP? </h3>
            <p>Any customers who would like to:</p>
            <ul>
             <li> Have a clear separation between production and non-production environments</li>
             <li> Avoid error prone manual deployments</li>
             <li> Improve data classification and protection</li>
             <li> Mandate compliant security controls</li>
             <li> Work around inconsistant naming conventions</li>
             <li> Improve their budgeting/billing strategies </li>
             <li> Poor identity and access management</li>
            </ul>
            <h3> End Result </h3>
            <p>In one week we will deliver a solution that:</p>    
            <ul>
                <li>Is fully managed by code (Terraform or CloudFormation)</li>
                <li>Implements Security and data protection best practices</li>
                <li>Allows for future changes in business needs without the need for rearchitecting</li>
                <li>Provides a clear separation between production and non-production workloads</li>
                <li>Optimises a budgeting and billing strategy </li>
                <li>With transparent and easy to understand billing. This includes the ability to create custom reports based on usage, cost centre, ownership and so on.</li>
            </ul>
            <h3> key features </h3>
            <p>The Complex integrations done for you:</p>
            <ul>
                <li>Infrastructure as code</li>
                <li>Account structure</li>
                <li>Security Features</li>
                <li>Audit Logs</li>
                <li>Consistency</li>
                <li>Cost Control and Billing </li>
                <li>Network Centralisation</li>
                <li>Network Separation</li>
                <li>Data Protection</li>
            </ul>
            <!-- <h3> Example Listing </h3>
            <p></p> -->
            <h3>Use case</h3>
              <p>
                  Medichart is a workflow automation company that serves the healthcase industry. 
              </p>
              <p>
                Medichart has to comply with various frameworks due to the nature of their application. They use Automatum to deploy a secure landing zone in under a week. This enabled them to focus on their software and reduce the burden of going through and audit before being operational in multiple hostpitals. 
                </p>
            <p>
            Checkout our <b>AWS Landing Zone</b> in the service catalogue or reach out to us for more information. 
            </p>

          </b-card-text>
        </b-tab>
        <b-tab title="Migration">
          <b-card-text>
            <h3> What is this? </h3>
            <p>A time-saving solution to migrate an existing application to into the AWS cloud. Best practices are applied to improve migration.</p>
            <h3> Who Can This HELP? </h3>
            <p>Any customers who would like to:</p>
            <ul>
              <li>Reduce costs by updating their stack to a modern, lower cost solution</li>
              <li>Move an on premises application to the cloud</li>
              <li>Update a cloud application to use the most up to date best security and deployment practices</li>
              <li>Audit and improve their existing infrastructure</li>
            </ul>
            <h3> End Result </h3>
            <p>We will modernise your stack by:</p>    
            <ul>
              <li>Discussing your needs and deployment models during a 1-2 hour meeting</li>
              <li>We will propose some changes to your stack that you authorise</li>
              <li>We will work with you to implement the changes we've proposed, providing support and guidance as necessary</li>
            </ul>
            <h3> key features </h3>
            <ul>
              <li>Architectural patterns in line with the latest best practices</li>
              <li>Scalable, secure architectures that can be deployed in minutes</li>
            </ul>
            <h3> Use cases </h3>
            <p>Billbergia is construction company. </p>

            <p> They used Automatum business solutions to build and migrate their architecture into AWS cloud to improve the resiliency and sclability of their application. </p>
            <p>
              Please reach out to discuss more complex migrations, or let us know if you have any questions. 
            </p>
          </b-card-text>
        </b-tab>
        <b-tab title="Application Modernization">
          <b-card-text>
            <h3> What is this? </h3>
            <p>Monolithic stacks are made into maintainable subservices using serverless, containerisation and microservice architectures.</p>
            <h3> Who Can This HELP? </h3>
            <p>Any customers who would like to:</p>
            <ul>
              <li>Reduce their stack infrastructure costs</li>
              <li>Scale their application to reach more users</li>
              <li>Mitigate and prevent security breaches</li>
              <li>Simplify the development and deployment lifecycle of an application</li>
            </ul>
            <h3> The Process </h3>
            <p>We will modernise your stack by:</p>    
            <ul>
              <li>Discussing your needs and deployment models during a 1-2 hour meeting</li>
              <li>We will propose some changes to your stack that you authorise</li>
              <li>We will work with you to implement the changes we've proposed, providing support and guidance as necessary</li>
            </ul>
            <h3> key features </h3>
            <ul>
              <li>Obligation free discussion with a senior</li>
              <li>Free best practices</li>
              <li>Multiple patterns ready to be deployed to help you get to your goals as fast as possible</li>
            </ul>

            <h3> Use cases </h3>
            <p> Q1 Designs is a software consultancy that builds and deploys scalable software solutions</p>
            <p> They used Automatum to deploy the required headless architecture for their solution in days not months. The scalable solution inline with best practice allowed them to focus on development of their solution rather infrastructure deployment tasks.</p>
            <p> Please reach out to discuss more complex Modernization, or let us know if you have any questions. </p>
            
            </b-card-text>
        </b-tab>
        <b-tab title="Well Architected Review">
          <b-card-text>
            <h3> What is this? </h3>
            <p>A 3 hour discussion around your current environment and setup and how it aligns with AWS's well architected framework. </p>
            <h3> Who Can This HELP? </h3>
            <p>Any customers who would like to:</p>
            <ul>
              <li>Validate that their production environment adheres to AWS's Well Architected Framework</li>
              <li>Improve their cost, security and operational impact on their business</li>
              <li>Passing the Foundation Technical Review (FTR) which is a requirement to become a technology partner or co-sell with AWS through the AWS Marketplace.</li>
            </ul>
            <h3> End Result </h3>
            <p>Within a week we will deliver a report that evaluates your environment against the six pillars of the Well Architected Framework:</p>    
            <ul>
                <li>Operational Excellence </li>
                <li>Security</li>
                <li>Reliability</li>
                <li>Performance Efficiency </li>
                <li>Cost Optimisation</li>
                <li>Sustainability </li>
              
            </ul>
            <h3> key features </h3>
            <ul>
              <li>An overview of your alignment with AWS best practice </li>
              <li>Advice on how to remediate any non-conformities</li>
            </ul>
            <!-- <h3> Example Listing </h3>
            <p></p> -->
            <p>
            Please book a time for your free review. 
            </p>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BButton,
  BImg,
  BTab,
  BTabs,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  methods: {
    kFormatter,
  },
};
</script>
