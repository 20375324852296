<template>
  <section id="dashboard-customer">
    <b-row class="match-height">
      <b-col>
        <customer-welcome />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import CustomerWelcome from "./CustomerWelcome.vue";
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,

    CustomerWelcome,
  },
  data: {
    data: {},
  },
};
</script>